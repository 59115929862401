import React from "react";

import { Sections as SharedSections, getProperty } from "@lachevaliniere/shared";

import { usePages, useStaticRooms } from "hooks";

export const Sections = () => {
  const pages = usePages();
  const staticRooms = useStaticRooms();
  const sections = getProperty(staticRooms, "data.sections") || [];

  return <SharedSections sections={sections} pages={pages} color="tertiary" />;
};
