import React, { Fragment } from "react";

import { Meta } from "./rooms/meta";
import { Sections } from "./rooms/sections";

export const Template = () => (
  <Fragment>
    <Meta />
    <Sections />
  </Fragment>
);

export default Template;
